/* eslint-disable max-lines-per-function */
import omit from 'lodash-es/omit';

import { format } from '../../../shared/date.helper';
import { authActionType } from '../../auth/auth.action';
import { UnsafeAction as Action } from '../../interfaces';
import { mfaActionType } from '../../mfa/mfa.action';
import { containsEntity, getEntities } from '../../shared/entity.helper';
import { addEntity, getEntity, mergeEntities, mergeEntity, updateEntitiesById } from '../orm';
import { employeeActionType } from './employee.action';
import { EmployeeModel, EmployeeState } from './employee.model';

const entityType = 'employees';

const initialState: EmployeeState = {
  items: [],
  itemsById: {},
  selectedEmployeeIds: [],
};

function assignState(state, ...newState) {
  return Object.assign({}, state, ...newState);
}

export function EmployeeReducer(state: EmployeeState = initialState, action: Action): EmployeeState {
  let payload = action.payload;

  switch (action.type) {
    case employeeActionType.REMOVE_SUCCESS:
      return updateEntitiesById(state, { enddate: format(new Date(), 'yyyy-MM-dd') }, payload);

    case employeeActionType.ANONYMIZE_SUCCESS:
      const user = payload.User;
      return updateEntitiesById(state, user, user.id);

    case employeeActionType.LOAD_FILES:
    case employeeActionType.LOAD_FILES_SUCCESS:
    case employeeActionType.LOAD_FILES_FAILED:

    case employeeActionType.DELETE_FILE_SUCCESS:

    case employeeActionType.ADD_FILE_SUCCESS:
    case employeeActionType.EDIT_FILE_SUCCESS:

    case employeeActionType.LOAD_NOTES:
    case employeeActionType.LOAD_NOTES_SUCCESS:
    case employeeActionType.LOAD_NOTES_FAILED:

    case employeeActionType.DELETE_NOTE_SUCCESS:

    case employeeActionType.ADD_NOTE_SUCCESS:
    case employeeActionType.EDIT_NOTE_SUCCESS:

    case employeeActionType.UPDATE_PERMISSIONS_SUCCESS:
    case employeeActionType.UPDATE_TEAMS_SUCCESS:
    case employeeActionType.DELETE_AVATAR_SUCCESS:
    case employeeActionType.UPDATE_AVATAR_SUCCESS: {
      return {
        ...state,
        itemsById: {
          ...state.itemsById,
          [payload.employeeId]: EmployeeItemReducer(state.itemsById[payload.employeeId], action),
        },
      };
    }

    case authActionType.LOGIN_SUCCESS:
    case authActionType.LOAD_SUCCESS:
      return addEntity(state, payload.User);

    case employeeActionType.LOAD_SUCCESS:
      return mergeEntities(state, getEntities(action, entityType));

    case employeeActionType.BULK_ADD:
      return { ...state, selectedEmployeeIds: payload };

    case employeeActionType.BULK_EMPTY:
      return { ...state, selectedEmployeeIds: [] };

    case mfaActionType.DISABLE_FOR_USER_SUCCESS:
      const entity = {
        ...getEntity(state, payload),
        mfa_enabled: false,
      };

      return mergeEntity(state, entity);

    default:
      if (containsEntity(action, entityType)) {
        return mergeEntities(state, getEntities(action, entityType));
      }

      return state;
  }
}

function EmployeeItemReducer(employee: EmployeeModel, action: Action): EmployeeModel {
  switch (action.type) {
    default:
      return employee;

    /*****************
     * USER FILES
     *****************/
    case employeeActionType.LOAD_FILES:
      return {
        ...employee,
        loadingFiles: true,
      };

    case employeeActionType.LOAD_FILES_SUCCESS:
      const files = action.payload.response.entities ? action.payload.response.entities.userFiles : {};
      return {
        ...employee,
        loadingFiles: false,
        Files: files,
      };

    case employeeActionType.LOAD_FILES_FAILED:
      return {
        ...employee,
        loadingFiles: false,
      };

    case employeeActionType.DELETE_AVATAR_SUCCESS: {
      return {
        ...employee,
        avatar_file_name: '',
        avatar_15x15: '',
        avatar_24x24: '',
        avatar_30x30: '',
        avatar_45x45: '',
        avatar_60x60: '',
        avatar_150x200: '',
      };
    }

    case employeeActionType.DELETE_FILE_SUCCESS:
      return {
        ...employee,
        Files: omit(employee.Files, action.payload.attachmentId),
      };

    case employeeActionType.ADD_FILE_SUCCESS:
    case employeeActionType.EDIT_FILE_SUCCESS:
      const newFiles = action.payload.response.entities ? action.payload.response.entities.userFiles : {};

      return {
        ...employee,
        Files: {
          ...employee.Files,
          ...newFiles,
        },
      };

    /*****************
     * USER NOTES
     *****************/

    case employeeActionType.LOAD_NOTES:
      return {
        ...employee,
        loadingNotes: true,
      };

    case employeeActionType.LOAD_NOTES_SUCCESS:
      const notes = action.payload.response.entities ? action.payload.response.entities.userNotes : {};
      return {
        ...employee,
        loadingNotes: false,
        Notes: notes,
      };

    case employeeActionType.LOAD_NOTES_FAILED:
      return {
        ...employee,
        loadingNotes: false,
      };

    case employeeActionType.DELETE_NOTE_SUCCESS:
      return {
        ...employee,
        Notes: omit(employee.Notes, action.payload.userNoteId),
      };

    case employeeActionType.ADD_NOTE_SUCCESS:
    case employeeActionType.EDIT_NOTE_SUCCESS:
      const newNotes = action.payload.response.entities ? action.payload.response.entities.userNotes : {};

      return {
        ...employee,
        Notes: {
          ...employee.Notes,
          ...newNotes,
        },
      };

    /*****************
     * Permissions and teams
     *****************/

    case employeeActionType.UPDATE_PERMISSIONS_SUCCESS:
    case employeeActionType.UPDATE_TEAMS_SUCCESS:
      return {
        ...employee,
        UsersGroup: action.payload.response.entities.employees[employee.id].UsersGroup,
        Team: action.payload.response.entities.employees[employee.id].Team,
      };

    /*****************
     * Avatar
     *****************/

    case employeeActionType.UPDATE_AVATAR_SUCCESS:
      return {
        ...employee,
        ...action.payload.response.User,
      };
  }
}
